export const SOCIAL_PLATFORMS = [
  {
    icon: "facebook",
    label: "Facebook",
    value: "facebook",
  },
  {
    icon: "twitter",
    label: "Twitter",
    value: "twitter",
  },
  {
    icon: "snapchat",
    label: "Snapchat",
    value: "snapchat",
  },
  {
    icon: "linkedin",
    label: "Linked In",
    value: "linkedin",
  },
  {
    icon: "threads",
    label: "Threads",
    value: "threads",
  },
  {
    icon: "instagram",
    label: "Instagram",
    value: "instagram",
  },
  {
    icon: "telegram",
    label: "Telegram",
    value: "telegram",
  },
  {
    icon: "youtube",
    label: "Youtube",
    value: "youtube",
  },
];

export const DESIGNATION_LISTS = [
  "Founder & CEO",
  "Director",
  "Proprietor",
  "Owner",
  "Manager",
];
export const PAYMENT_PLATFORMS = [
  { icon: "phonepe", label: "Phonepe", value: "phonepe" },
  { icon: "gpay", label: "G-Pay", value: "gpay" },
  { icon: "paytm", label: "Paytm", value: "paytm" },
  { icon: "bhim", label: "BHIM", value: "bhim" },
  { icon: "bharatpay", label: "Bharat pay", value: "bharatpay" },
];

export const FILTER_OPTIONS = [
  {
    id: 1,
    value: "LATEST",
    label: "Latest",
  },
  {
    id: 2,
    value: "OLDEST",
    label: "Oldest",
  },
  {
    id: 3,
    value: "HIGH_TO_LOW_PRICE",
    label: "High to low price",
  },
  {
    id: 4,
    value: "LOW_TO_HIGH_PRICE",
    label: "Low to high price",
  },
];

export const IMAGE_SETTINGS = {
  SIZE_200_X_200: {
    maxHeight: 200,
    maxWidth: 200,
    aspect: 1,
  },
  SIZE_300_X_200: {
    maxHeight: 200,
    maxWidth: 300,
    aspect: 3 / 2,
  },
  SIZE_150_X_150: {
    maxHeight: 150,
    maxWidth: 150,
    aspect: 1,
  },
  SIZE_300_X_300: {
    maxHeight: 300,
    maxWidth: 300,
    aspect: 1,
  },
};
export const RAZORPAY_KEYS = {
  // test key
  TEST_API_KEY: "rzp_test_0UuCLmaXbqMTXz",
  TEST_API_SECRET: "jzulp43OyvJyHPYS7DHE9USY",
  // live key
  LIVE_API_KEY: "rzp_live_OFcLEQgnM8NRRF",
  LIVE_API_SECRET: "I4JEE01AhJ9J5KzPz7sf1963",
};

export const PROFILE_LAST_STEPS = {
  OTP: "otp",
  CHOOSE_PLAN: "choose-plan",
  CHOOSE_CATEGORY: "choose-category",
  SAVE_PERSONAL_INFO: "save-personal-info",
  SAVE_BUSINESS_INFO: "save-business-info",
  SAVE_SERVICE_INFO: "save-product-info",
  SAVE_VEHICLE_INFO: "save-vehicle-info",
  SAVE_PAYMENT_INFO: "save-payment-info",
  SAVE_CUSTOM_URL: "save-custom-url",
  COMPLTED: "completed",
};
export const PAGINATION_LIMIT = 10;
export const PLAN_PRICES = {
  // monthly: 149,
  // yearly: 999,
  // monthly: 249, //updated on 01-05-2024
  // yearly: 1799, //updated on 01-05-2024
  monthly: 499, //updated on 31-08-2024
  yearly: 1799, //updated on 31-08-2024 
};

export const ABROAD_PLAN_PRICES = {
  monthly: 7,
  yearly: 29,
};

export const VALID_IMAGE =
  ' "uploaded file is not valid. only jpg, jpeg, png, svg, webp files are allowed"';
export const CATEGORY_LISTS = {
  ALL_SERVICE: "All Services",
  ONLINE_STORE: "Online Store",
  RETAIL_SHOP: "Retail Shop",
  RESTAURANT: "Restaurant",
  DOCTOR: "Doctor",
  HOTEL: "Hotels",
  SCHOOL_COLLEGE: "School/College",
  TRAVEL: "Travels",
  TEACHER: "Teacher",
  FARMING: "Farming",
  POLITICIAN: "Politician",
  PERSONAL: "Personal",
};
export const FILTER_TRAVEL_OPTIONS = [
  {
    id: 1,
    value: "all",
    label: "All",
  },
  {
    id: 2,
    value: "vehicleInfo",
    label: "Vehicle",
  },
  {
    id: 3,
    value: "serviceInfo",
    label: "Service",
  },
  {
    id: 4,
    value: "packageInfo",
    label: "Package",
  },
];
