import React from 'react'
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
// import header footer
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import SiteFooterTop from './SiteFooterTop';
import useScrollToTop from "../common/hooks/useScrollToTop";
const ViewDemoWebsite = () => {
    useScrollToTop();
    return (
        <div className=" website_pages site_contactus">
            <SiteHeader />
            <div className="page view-demo-website">
                <Modal.Header closeButton>
                    <Modal.Title className="m18">View Demo Website</Modal.Title>

                </Modal.Header>
                <div className="vg22"></div>
                <Modal.Body>
                    <h6 className="r16 text-start">
                    Here are some links to original websites created by BusinessBaab.
                    </h6>
                    <h6 className="r16 text-start mt-2">
                    Your website will look similar, you can check them out.
                    </h6>
                    <div className="vg22"></div>
                    <div className="demo_website_links">
                                <Link to='https://www.businessbaab.com/happy_journey_travel' className="dwl_single">
                                  <div className="left">
                                    <span class="material-symbols-outlined">
                                      visibility
                                    </span>
                                    Happy Journey Travels
                                  </div>
                    
                                  <span class="material-symbols-outlined">
                                    arrow_forward
                                  </span>
                    
                                </Link>
                                <Link to='https://businessbaab.com/absne' className="dwl_single">
                                  <div className="left">
                                    <span class="material-symbols-outlined">
                                      visibility
                                    </span>
                                    Absne Private Limited
                                  </div>
                    
                                  <span class="material-symbols-outlined">
                                    arrow_forward
                                  </span>
                    
                                </Link>
                                <Link to='https://businessbaab.com/shree_sanwariya_decorations' className="dwl_single">
                                  <div className="left">
                                    <span class="material-symbols-outlined">
                                      visibility
                                    </span>
                                    <span>
                                    <span>
                                    Shree Sanwariya Decoration
                                    </span>
                                    </span>
                                  </div>
                    
                                  <span class="material-symbols-outlined">
                                    arrow_forward
                                  </span>
                    
                                </Link>
                                <Link to='https://businessbaab.com/a2z' className="dwl_single">
                                  <div className="left">
                                    <span class="material-symbols-outlined">
                                      visibility
                                    </span>
                                    A 2 Z Collections
                                  </div>
                    
                                  <span class="material-symbols-outlined">
                                    arrow_forward
                                  </span>
                    
                                </Link>
                                <Link to='https://businessbaab.com/bushra-graphics' className="dwl_single">
                                  <div className="left">
                                    <span class="material-symbols-outlined">
                                      visibility
                                    </span>
                                    Bushra Graphics
                                  </div>
                    
                                  <span class="material-symbols-outlined">
                                    arrow_forward
                                  </span>
                    
                                </Link>            
                              </div>
                </Modal.Body>
                <div className="vg22"></div>
                {/* <div className="modal_btn">Okay, Got it.</div> */}
            </div>
            <SiteFooterTop />
            <SiteFooter />
        </div>
    )
}

export default ViewDemoWebsite
