import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ASSETS from "../../../../common/images";
import {
  createOrder,
  verifyPayment,
} from "../../../../store/actions/paymentActions";
import { useDispatch, useSelector } from "react-redux";
import ActivityIndigator from "../../../../shared/ActivityIndigator";
import usePageTitle from "../../../../common/hooks/usePageTitle";
import RenderRazorpay from "../../Gateway/RenderRazorpay";
import { useCookies } from "react-cookie";
import {
  ABROAD_PLAN_PRICES,
  PLAN_PRICES,
  RAZORPAY_KEYS,
} from "../../../../common/constants";
import { showError } from "../../../../store/common/helpers/responseHelper";

const ChoosePlan = () => {
  const [plan, setPlan] = useState(null);
  const { submitting } = useSelector((state) => state.form);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [liveMode, setLiveMode] = useState(true);
  const [creating, setCreating] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  usePageTitle("");

  console.log(user);

  const getPrice = (amount) => {
    if (cookies?.referralCode) {
      return amount - Math.round((amount * 10) / 100);
    }
    return amount;
  };

  const handleCreateOrder = async (amount, currency) => {
    setCreating(true);
    let discountType = null;
    if (cookies?.referralCode) {
      discountType = "promoter";
    }
    const data = await createOrder(
      {
        planType: plan,
        discountType: discountType,
        mode: liveMode ? "live" : "test",
      },
      setCreating
    );

    if (data && data?.order?.id) {
      setOrderDetails({
        orderId: data?.order?.id,
        currency: data?.order?.currency,
        amount: data?.order?.amount,
        order: data?.order,
        otherInfo: data?.otherInfo,
      });

      setDisplayRazorpay(true);
    }
  };

  // informing server about payment
  const handlePayment = async (status, orderDetails = {}) => {
    if (status !== "succeeded") {
      //   setOrderDetails({ orderId: null, currency: null, amount: null });
      setDisplayRazorpay(false);
      showError(`Payment ${status}`); //yh comment hi rhegi
      return;
    }
    dispatch(
      verifyPayment(
        {
          status,
          ...orderDetails,
          localDetails: {
            profileLastStep: "choose-plan",
            profileStep: 1,
            subscription: {
              ...(user.subscription || {}),
              isFreeTrial: false,
              isActive: true,
            },
          },
        },
        navigate
      )
    );
    // remove code from cookie
    if (cookies?.referralCode) {
      removeCookie("referralCode");
    }
  };

  return (
    <div className="create_protal pg_chooseplan">
      <div className="cp_inner">
        <div className="logo">
          <img src={ASSETS.businessBaapLogo} alt="" />
        </div>
        <div className="vg22"></div>
        <h2 className="sb22 text-center">Choose your plan</h2>
        <div className="vg22"></div>
        <div className="vg22"></div>
        <div className="big_cards">
          <div
            className={`bc_single ${plan == "monthly" ? "select_portal" : ""}`}
            onClick={() => setPlan("monthly")}
          >
            <div className="bcs_inner">
              {user?.isAbroadAccount ? (
                <h5 className="sb30">
                  {getPrice(ABROAD_PLAN_PRICES.monthly)} USD
                </h5>
              ) : (
                <h5 className="sb30">₹{getPrice(PLAN_PRICES.monthly)}</h5>
              )}

              <h6 className="r18">Monthly</h6>
            </div>
            {plan == "monthly" && (
              <span className="material-symbols-outlined check">task_alt</span>
            )}
          </div>
          <div
            className={`bc_single ${plan == "yearly" ? "select_portal" : ""}`}
            onClick={() => setPlan("yearly")}
          >
            <div className="top r12 grey">Recommended</div>
            <div className="bcs_inner">
              {user?.isAbroadAccount ? (
                <h5 className="sb30">
                  {getPrice(ABROAD_PLAN_PRICES.yearly)} USD
                </h5>
              ) : (
                <h5 className="sb30">₹{getPrice(PLAN_PRICES.yearly)}</h5>
              )}

              <h6 className="r18">Yearly</h6>
            </div>
            <div className="bottom">
              {user?.isAbroadAccount ? (
                <span>
                  {" "}
                  ({Math.round(getPrice(ABROAD_PLAN_PRICES.yearly / 12))}{" "}
                  USD/month)
                </span>
              ) : (
                <span>
                  {" "}
                  (₹{Math.round(getPrice(PLAN_PRICES.yearly / 12))}/month)
                </span>
              )}
            </div>
            {plan == "yearly" && (
              <span className="material-symbols-outlined check">task_alt</span>
            )}
          </div>
        </div>
        <div className="vg22"></div>
        {plan !== null && (
          <button
            type="button"
            className="theme_btn"
            onClick={handleCreateOrder}
            disabled={creating}
          >
            {creating ? <ActivityIndigator /> : "Purchase Plan"}
          </button>
        )}

        {displayRazorpay && (
          <RenderRazorpay
            amount={orderDetails.amount}
            currency={orderDetails.currency}
            orderId={orderDetails.orderId}
            orderDetails={orderDetails}
            keyId={
              liveMode ? RAZORPAY_KEYS.LIVE_API_KEY : RAZORPAY_KEYS.TEST_API_KEY
            }
            keySecret={
              liveMode
                ? RAZORPAY_KEYS.LIVE_API_SECRET
                : RAZORPAY_KEYS.TEST_API_SECRET
            }
            handlePayment={handlePayment}
          />
        )}
      </div>
      <div className="vg22"></div>
      <div className="text-center">
        <Link to={`/account/choose-plan`} className="grad_text gt_border">
          Back
        </Link>
      </div>
    </div>
  );
};

export default ChoosePlan;
