// development
const environment = "prod"; //prod
// production
const BASE_URL =
  environment === "dev"
    ? "http://127.0.0.1:5000/api/v1"
    : "https://api.businessbaab.com/api/v1";
const BACKEND_ASSETS =
  environment === "dev"
    ? "http://127.0.0.1:4000"
    : "https://api.businessbaab.com/";

const AUTHORIZATION = "Authorization";
const COOKIE_EXPIRED = 60 * 60 * 24 * 365;
const APIs = {
  // auth
  CREATE_ACCOUNT: "/auth/register",
  LOGIN: "/auth/login",
  SEND_OTP: "/auth/sendOtp",
  VERIFY_OTP: "/auth/validateOtp",
  // account
  SEND_OTP_FOR_DELETE_ACCOUNT_CONFIRMATION: "/auth/sendOtpForDeleteAccount",
  VERIFY_ACTION_OTP: "/auth/validateActionOtp",
  SENT_OTP_FOR_COMMON_ACTION: "/auth/sendActionOtp",

  // common
  GET_ALL_COUNTRY: "/common/countries",
  GET_ALL_STATE_BY_COUNTRY: "/common/states",
  GET_ALL_CITY_BY_STATE: "/common/cities",
  //category
  GET_ALL_CATEGORIES: "/category",
  //user related
  GET_FILTER_DATA: "/orders/filters",
  GET_ALL_ORDERS: "/orders/all",
  CHANGE_ORDER_STATUS: "/orders/updateOrderStatus",
  DELETE_ORDER: "/orders/deleteOrder",

  ADD_NEW_SERVICE: "/services/add",
  UPDATE_EXISTING_SERVICE: "/services/update",
  GET_ALL_SERVICES: "/services/all",
  GET_SINGLE_SERVICE: "/services/getService",
  DELETE_SERVICE: "/services/deleteService",

  // travel services
  ADD_NEW_TRAVEL_SERVICE: "/travel-services/add",
  UPDATE_EXISTING_TRAVEL_SERVICE: "/travel-services/update",
  GET_ALL_TRAVEL_SERVICES: "/travel-services/all",
  GET_SINGLE_TRAVEL_SERVICE: "/travel-services/getService",
  DELETE_TRAVEL_SERVICE: "/travel-services/deleteService",

  // common endpoint for change user data
  CHANGE_USER_DATA: "/user/changeUserStatus",
  CHANGE_USERNAME: "/user/changeUserName",
  UPDATE_USER_PERSONAL_INFO: "/user/updateProfile",
  UPDATE_USER_BUSINESS_INFO: "/user/updateBusinessInfo",
  GET_USER_DETAILS: "/user/getUserInfo",
  DELETE_BUSINESS_IMAGE: "/user/banner/image",
  DELETE_BUSINESS_LOGO: "/user/deleteBusinessLogo",

  ADD_PAYMENT_INFO: "/user/addEditPaymentInfo",
  DELETE_PAYMENT_INFO: "/user/deletePaymentInfo",
  GET_ALL_PAYMENT_INFO: "/user/getAllPaymentInfo",

  //delete contact
  DELET_CONTACT_DETAILS: "/public/contact/delete",

  // subscription
  PURCHASE_SUBSCRIPTION: "/user/purchaseSubscription",
  VERIFY_PAYMENT: "/user/verifyPayment",

  ALLOW_FREE_TRIAL: "/user/allowFreeTrial",

  // analtyics
  GET_ANALYTICS_COUNTS: "/user/getAnalyticCounts",

  //public end points
  GET_USER_CARD_DETAILS: "/public/user",
  GENERATE_CARD_DETAILS: "/public/generate",
  GET_USER_ALL_SERVICES: "/public/service/all",
  GET_USER_SINGLE_SERVICE: "/public/service",
  PLACE_ORDER: "/public/order/placeOrder",

  //contact & support
  SEND_CONTACT_DETAILS: "/public/contact/send",
  SEND_SUPPORT_DETAILS: "/public/support/send",

  // promotors
  REGISTER_PROMOTER: "/promoter/register",
  //admin
};

const NAVIGATION_ROUTE = {
  SELECT_ACCOUNT_TYPE: "/account/select-account-type",
  USER_ACCOUNT: "/account",
  USER_DASHBOARD: "/dashboard",
  PERSONAL_INFO: "/account/personal-info",
  BUSINESS_INFO: "/account/business-info",
  SERVICE_INFO: "/account/service-info",
  VEHICLE_INFO: "/account/vehicle-info",
  PAYMENT_INFO: "/account/payment-info",
  CHOOSE_PLAN: "/account/choose-plan",
  CONGRADULATION: "/account/congratulation",
  RECHARGE: "/account/recharge",
  PAYMENT_SUCCESS: "/account/recharge-success",
};

export {
  BASE_URL,
  BACKEND_ASSETS,
  AUTHORIZATION,
  COOKIE_EXPIRED,
  APIs,
  NAVIGATION_ROUTE,
};
