import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ASSETS from "../../../common/images";
import Modal from "react-bootstrap/Modal";

const ProductSingle = ({ service, backendAssetUrl, username }) => {
  //   modal
  const [showMyProduct, setShowMyProduct] = useState(false);

  const handleCloseMyProductModal = () => setShowMyProduct(false);
  const handleShowMyProductModal = () => setShowMyProduct(true);
  //   modal
  return (
    <div
      className={`product_single ${service?.quantity === 0 ? "out_off_stock" : null
        }`}
    >
      <div className="product_img">
        <img
          src={
            service?.thumbnail
              ? service.thumbnail
              : ASSETS.dummyproductimg
          }
          alt={service?.title}
          onClick={handleShowMyProductModal}
        />
      </div>
      {/* modal for product image  */}
      <Modal
        show={showMyProduct}
        onHide={handleCloseMyProductModal}
        className="my_modal modal_top"
      >
        <Modal.Header className="d-flex">
          {/* <Modal.Title className="m18">{service?.thumbnail ? (
            ""
          ) : (
            "Alert"
          )}</Modal.Title> */}
          {/* <span
            class="material-symbols-outlined modal_close red"
            onClick={handleCloseMyProductModal}
          >
            close
          </span> */}
         
        <div onClick={handleCloseMyProductModal} className="modal_btn modal_btn_border modal_top_btn">Close</div>
    
        <Link to={`/${username}/product/${service?._id}`} className="modal_btn modal_top_btn">View</Link>

        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6>
            {service?.thumbnail ? (
              <>
                {/* {service?.title} product image */}
                
              </>
            ) : (
              "Image is not available :("
            )}
          </h6>
          {service?.thumbnail &&
            <div className="modal_product_img">
              <img
                src={
                  `${backendAssetUrl}/${service.thumbnail}`
                }
                alt={service?.title}
                className="w-100"
              />
            </div>
          }

        </Modal.Body>
      
      </Modal>
      <div className="product_desc">
       <div className="pd_inner">
       <h5 className="price sb22">₹ {service?.price || ""}</h5>
        <h6 className="r14 only_one_line">{service?.title || ""}</h6>
       </div>
        <div className="vg8"></div>
        <Link
          to={`/${username}/product/${service?._id}`}
          className="theme_btn short_btn view_btn "
        >
          View
        </Link>
      </div>
    </div>
  );
};

export default ProductSingle;
