import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
// vendor navbar
import VendorNavbar from "../component/VendorNavbar";
import PhoneInput from "react-phone-number-input";
import GoogleMetaTag from "../component/GoogleMetaTag";
import "react-phone-number-input/style.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextError from "../shared/TextError";
import { phoneNumberValidate } from "../common/validations/formValidation";
import { connect } from "react-redux";
import { getSession, sendOTP } from "../store/actions/authActions";
import ActivityIndigator from "../shared/ActivityIndigator";
import { useCookies } from "react-cookie";
import useRefferalCode from "../common/hooks/useRefferalCode";

const Login = ({ sendOTPHandler, submitting, isLogin }) => {
  let userInfo = getSession();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);

  useEffect(() => {
    if (userInfo.Authorization) {
      navigate("/account");
    }
  }, [navigate, userInfo.Authorization]);

  const initialValues = {
    phoneNumber: "",
    termsAndConditions: isLogin ? true : false,
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    sendOTPHandler(
      {
        ...values,
        referralCode: cookies?.referralCode || "",
      },
      resetForm,
      onAction
    );
  };

  const onAction = () => {
    // removeCookie("referralCode");
    navigate("/verify-otp");
  };
  useRefferalCode();

  //   modal for view demo website links
  const [showDemoWebsite, setShowDemoWebsite] = useState(false);

  const handleCloseDemoWebsiteModal = () => setShowDemoWebsite(false);
  const handleShowDemoWebsiteModal = () => setShowDemoWebsite(true);
  //   modal for view demo website links

  return (
    <div className="vendor_page_spacing">
      {/* <VendorNavbar title="" backgroundColor="white" backArrow="" /> */}
      <GoogleMetaTag title={"Login"} content={"Login page of businessbaab website content"} />
      <div className="create_protal align_center_pg">
        <div className="cp_inner acp_inner text-center">
          <div className="logo">
            <img src="./assets/img/logo_businessbaab.svg" alt="" />
          </div>
          <div className="vg22"></div>
          <h2 className="sb22">Welcome to BusinessBaab</h2>
          <div className="vg22"></div>
          <h4 className="r18">{isLogin ? "Login" : "Login"}</h4>
          <div className="vg22"></div>
          <div className="vg22"></div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={phoneNumberValidate}
          >
            <Form>
              <div className="form_field loginnumber">
                <label htmlFor="">Enter Your Mobile Number*</label>
                <Field name="phoneNumber">
                  {({ field, form }) => (
                    <PhoneInput
                      {...field}
                      international
                      defaultCountry="IN"
                      withCountryCallingCode={true}
                      countryCallingCodeEditable={false}
                      id="phoneNumber"
                      name="phoneNumber"
                      onBlur={field.onBlur}
                      onChange={(value) =>
                        form.setFieldValue(field.name, value)
                      }
                    />
                  )}
                </Field>{" "}
                <ErrorMessage name="phoneNumber" component={TextError} />
              </div>
              {!isLogin && (
                <div className="form_field mt-1">
                  <Field name="termsAndConditions">
                    {({ field }) => (
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          {...field}
                          checked={field.value}
                          id="t_c"
                        />
                        <label htmlFor="t_c">
                          {" "}
                          I agreed with Terms and Privacy{" "}
                          <Link
                            to="/termsandpolicy"
                            className="grad_text gt_border"
                            target="_blank"
                          >
                            See
                          </Link>
                        </label>
                      </div>
                    )}
                  </Field>
                </div>
              )}
              <div className="vg22"></div>{" "}
              <button type="submit" className="theme_btn" disabled={submitting}>
                {" "}
                {submitting ? <ActivityIndigator /> : "Send OTP "}
              </button>
              <div className="vg22"></div>{" "}
              <Link to='/' className="grad_text gt_border">Go To Website</Link>
              <div className="vg12"></div>
              <div className="grad_text d-flex align-items-center justify-content-center" style={{
                gap: "5px"
              }}
                onClick={handleShowDemoWebsiteModal}>
                <span class="material-symbols-outlined" style={{
                  fontSize: "20px"
                }}>
                  visibility
                </span>
                <span className="gt_border grad_text">View Demo Website</span>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
      {/* modal for show demo website links  */}
      <Modal
        show={showDemoWebsite}
        onHide={handleCloseDemoWebsiteModal}
        className="my_modal modal_top vdw_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="m18">View Demo Website</Modal.Title>
          <span
            class="material-symbols-outlined modal_close red"
            onClick={handleCloseDemoWebsiteModal}
          >
            close
          </span>
        </Modal.Header>
        <div className="vg22"></div>
        <Modal.Body>
          <h6 className="r16 text-start">
          Here are some links to original websites created by BusinessBaab.
          </h6>
          <h6 className="r16 text-start">
          Your website will look similar, you can check them out.
          </h6>
          <div className="vg22"></div>
          <div className="demo_website_links">
            <Link to='https://www.businessbaab.com/happy_journey_travel' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Happy Journey Travels
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/absne' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Absne Private Limited
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/shree_sanwariya_decorations' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                <span>
                <span>
                Shree Sanwariya Decoration
                </span>
                </span>
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/a2z' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                A 2 Z Collections
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>
            <Link to='https://businessbaab.com/bushra-graphics' className="dwl_single">
              <div className="left">
                <span class="material-symbols-outlined">
                  visibility
                </span>
                Bushra Graphics
              </div>

              <span class="material-symbols-outlined">
                arrow_forward
              </span>

            </Link>            
          </div>
        </Modal.Body>
        <div className="vg22"></div>
        <div className="modal_btn" onClick={handleCloseDemoWebsiteModal}>Okay, Got it.</div>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ form, auth }) => {
  return {
    submitting: form.submitting,
    success: form.success,
    tempInfo: auth.tempInfo,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    sendOTPHandler: (data, resetForm, onAction) =>
      dispatch(sendOTP(data, resetForm, onAction)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
