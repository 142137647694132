import React from "react";
import { Link } from "react-router-dom";

// import live chat
import LiveChatScript from "./LiveChatScript";
import WhatsappIcon from "./WhatsappIcon";

const SiteFooter = () => {
  return (
    <div>
      <section className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer_content">
                <img
                  src="./assets/bbsite_images/logo_businessbaab.svg"
                  alt="Business Baab Logo"
                  className="footer_logo"
                />
                <div className="fs_logo">
                  <img src="./assets/bbsite_images/lion.png" alt="" />
                  <img src="./assets/bbsite_images/startup.svg" alt="" />
                </div>
                {/* <p>
                  Discover a seamless platform tailored to showcase your
                  business online. Whether you're an online shop, service
                  provider, restaurant, hotel, medical professional, retail
                  shop, educational institution, travel agency, farmer, teacher,
                  or politician, BusinessBaab offers you the opportunity to
                  create a stunning website with ease. Highlight your offerings,
                  connect with your audience, and expand your reach—all at an
                  affordable price. With user-friendly tools and dedicated
                  support, take your business to new heights in the digital
                  world.
                </p> */}
                <p>
                  At BusinessBaab you can create own professional website in
                  just 15 minutes without any coding knowledge or help from IT
                  company. That too in just ₹150 per month. At BusinessBaab, we
                  understand the challenges small businesses face in today's
                  online world. That is why we have brought a platform that
                  simplifies the process of creating a website. You just focus
                  on your business, leave all the technology problems to us for
                  just ₹150 per month.
                </p>
                <div className="social_share">
                  <Link to="https://www.linkedin.com/company/businessbaab/?viewAsMember=true" target="_blank">
                    <img
                      src="./assets/bbsite_images/icon_linkedin.svg"
                      alt="Icon"
                    />
                  </Link>
                  <Link to="https://www.facebook.com/profile.php?id=61556441522915" target="_blank">
                    <img
                      src="./assets/bbsite_images/icon_facebook.svg"
                      alt="Icon"
                    />
                  </Link>
                  <Link to="https://www.instagram.com/businessbaab/" target="_blank">
                    <img
                      src="./assets/bbsite_images/icon_instagram.svg"
                      alt="Icon"
                    />
                  </Link>
                  {/* <Link to="/WebsiteHome">
                    <img
                      src="./assets/bbsite_images/icon_linkedin.svg"
                      alt="Icon"
                    />
                  </Link> */}
                </div>
                <div className="footer_menu">
                  <Link to="/">Home</Link>
                  <Link to="/about-us">About Us</Link>
                  <Link to="/categories">Categories</Link>
                  <Link to="/terms-conditions">Terms & Conditions</Link>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                  <Link to="/refund-policy">Refund Policy</Link>

                  <Link to="/contact-us">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="copyright_text">
                All rights are reserved
                <span>Powered by Absne Private Limited</span>
              </div>
            </div>
          </div>
        </div>
      </section>    
      <WhatsappIcon/>
    </div>
  );
};

export default SiteFooter;
